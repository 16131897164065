export default [
  {
    artStyle: "Romanticism",
    detail: 50,
    image: "/assets/images/examples/ozymandias.avif",
    text: `I met a traveller from an antique land,

Who said—“Two vast and trunkless legs of stone

Stand in the desert. . . . Near them, on the sand,

Half sunk a shattered visage lies, whose frown,

And wrinkled lip, and sneer of cold command,

Tell that its sculptor well those passions read

Which yet survive, stamped on these lifeless things,

The hand that mocked them, and the heart that fed;

And on the pedestal, these words appear:

My name is Ozymandias, King of Kings;

Look on my Works, ye Mighty, and despair!

Nothing beside remains. Round the decay

Of that colossal Wreck, boundless and bare

The lone and level sands stretch far away.`,
    title: '👑 "Ozymandias" by Percy Bysshe Shelley',
  },
  {
    artStyle: "Charcoal",
    detail: 20,
    image: "/assets/images/examples/horror.avif",
    text: `In the dead of night, the house seemed to breathe with a life of its own, creaking and settling in the cold embrace of the darkness. I had convinced myself that the chill running down my spine was just the draft sneaking through the old windows, that the shadows dancing at the edge of my vision were merely tricks of the light—or the lack thereof. But as I stood there, in the dimly lit hallway of what was supposed to be my sanctuary, a palpable sense of dread began to coil around my heart.

It started as a whisper of intuition, a nagging thought that refused to be silenced. The kind of thought that festers, growing more insistent with each passing second. The realization hit me with the force of a freight train: I was not alone. The thing that had been stalking me, an unseen horror that lurked in the shadows of my nightmares, had somehow crossed the threshold into my reality. It was here, inside the house with me.

The air grew thick, heavy with anticipation, as if the very atmosphere was waiting with bated breath for what would come next. My own breathing sounded too loud in my ears, ragged and uneven from the adrenaline that spiked through my veins. I wanted to move, to run, to do anything but stand frozen in fear, but my legs refused to obey. They knew, just as I did, that there was nowhere left to run.

Every creak of the floorboards, every rustle of the wind against the windows, set my nerves on edge. The boundaries between the rational and the irrational began to blur, leaving me in a state of heightened alertness, where every shadow seemed to move with sinister intent. I could feel its presence drawing closer, a malevolent force that thrived on fear. My fear.

The realization that the door to the basement was ajar sent a new wave of terror crashing over me. I had checked those locks, double-checked them, because I knew—*knew*—that what hunted me came from the darkness below. And yet, there it was, a sliver of blackness, a gateway to an abyss that promised nothing but despair.

In that moment, the illusion of safety shattered. My home, once a place of comfort and solace, had become a hunting ground. And I, the prey, was trapped inside with a predator that knew no mercy, only an insatiable hunger. The darkness seemed to close in around me, smothering, as the very air whispered of my impending doom.

With a heart that felt like it might beat out of my chest, I made a decision. It was no longer about escape; it was about survival. The fight would come to me, here, in the heart of my own home. And I would face it, whatever it may be, with every ounce of courage I could muster. For in the depths of horror, when faced with the unimaginable, we find out what we truly are. And I was determined to face the darkness, not as prey, but as something it had never anticipated: a challenger who would not go quietly into the night.
`,
    title: "👻 An excerpt from a horror novel",
  },
  {
    artStyle: "Macabre Art",
    detail: 65,
    image: "/assets/images/examples/raven.avif",
    text: `Once upon a midnight dreary, while I pondered, weak and weary,  
Over many a quaint and curious volume of forgotten lore—  
&nbsp;&nbsp;&nbsp;&nbsp;While I nodded, nearly napping, suddenly there came a tapping,  
As of some one gently rapping, rapping at my chamber door.  
"’Tis some visitor," I muttered, "tapping at my chamber door—  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Only this and nothing more."

&nbsp;&nbsp;&nbsp;&nbsp;Ah, distinctly I remember it was in the bleak December;  
And each separate dying ember wrought its ghost upon the floor.  
&nbsp;&nbsp;&nbsp;&nbsp;Eagerly I wished the morrow;—vainly I had sought to borrow  
&nbsp;&nbsp;&nbsp;&nbsp;From my books surcease of sorrow—sorrow for the lost Lenore—  
For the rare and radiant maiden whom the angels name Lenore—  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nameless here for evermore.

&nbsp;&nbsp;&nbsp;&nbsp;And the silken, sad, uncertain rustling of each purple curtain  
Thrilled me—filled me with fantastic terrors never felt before;  
&nbsp;&nbsp;&nbsp;&nbsp;So that now, to still the beating of my heart, I stood repeating  
&nbsp;&nbsp;&nbsp;&nbsp;"’Tis some visitor entreating entrance at my chamber door—  
Some late visitor entreating entrance at my chamber door;—  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This it is and nothing more."

&nbsp;&nbsp;&nbsp;&nbsp;Presently my soul grew stronger; hesitating then no longer,  
"Sir," said I, "or Madam, truly your forgiveness I implore;  
&nbsp;&nbsp;&nbsp;&nbsp;But the fact is I was napping, and so gently you came rapping,  
&nbsp;&nbsp;&nbsp;&nbsp;And so faintly you came tapping, tapping at my chamber door,  
That I scarce was sure I heard you"—here I opened wide the door;—  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Darkness there and nothing more.

&nbsp;&nbsp;&nbsp;&nbsp;Deep into that darkness peering, long I stood there wondering, fearing,  
Doubting, dreaming dreams no mortal ever dared to dream before;  
&nbsp;&nbsp;&nbsp;&nbsp;But the silence was unbroken, and the stillness gave no token,  
&nbsp;&nbsp;&nbsp;&nbsp;And the only word there spoken was the whispered word, "Lenore?"  
This I whispered, and an echo murmured back the word, "Lenore!"—  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Merely this and nothing more.

&nbsp;&nbsp;&nbsp;&nbsp;Back into the chamber turning, all my soul within me burning,  
Soon again I heard a tapping somewhat louder than before.  
&nbsp;&nbsp;&nbsp;&nbsp;"Surely," said I, "surely that is something at my window lattice;  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Let me see, then, what thereat is, and this mystery explore—  
Let my heart be still a moment and this mystery explore;—  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;’Tis the wind and nothing more!"

&nbsp;&nbsp;&nbsp;&nbsp;Open here I flung the shutter, when, with many a flirt and flutter,  
In there stepped a stately Raven of the saintly days of yore;  
&nbsp;&nbsp;&nbsp;&nbsp;Not the least obeisance made he; not a minute stopped or stayed he;  
&nbsp;&nbsp;&nbsp;&nbsp;But, with mien of lord or lady, perched above my chamber door—  
Perched upon a bust of Pallas just above my chamber door—  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Perched, and sat, and nothing more.

Then this ebony bird beguiling my sad fancy into smiling,  
By the grave and stern decorum of the countenance it wore,  
"Though thy crest be shorn and shaven, thou," I said, "art sure no craven,  
Ghastly grim and ancient Raven wandering from the Nightly shore—  
Tell me what thy lordly name is on the Night’s Plutonian shore!"  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Quoth the Raven "Nevermore."

&nbsp;&nbsp;&nbsp;&nbsp;Much I marvelled this ungainly fowl to hear discourse so plainly,  
Though its answer little meaning—little relevancy bore;  
&nbsp;&nbsp;&nbsp;&nbsp;For we cannot help agreeing that no living human being  
&nbsp;&nbsp;&nbsp;&nbsp;Ever yet was blessed with seeing bird above his chamber door—  
Bird or beast upon the sculptured bust above his chamber door,  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With such name as "Nevermore."

&nbsp;&nbsp;&nbsp;&nbsp;But the Raven, sitting lonely on the placid bust, spoke only  
That one word, as if his soul in that one word he did outpour.  
&nbsp;&nbsp;&nbsp;&nbsp;Nothing farther then he uttered—not a feather then he fluttered—  
&nbsp;&nbsp;&nbsp;&nbsp;Till I scarcely more than muttered "Other friends have flown before—  
On the morrow he will leave me, as my Hopes have flown before."  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Then the bird said "Nevermore."

&nbsp;&nbsp;&nbsp;&nbsp;Startled at the stillness broken by reply so aptly spoken,  
"Doubtless," said I, "what it utters is its only stock and store  
&nbsp;&nbsp;&nbsp;&nbsp;Caught from some unhappy master whom unmerciful Disaster  
&nbsp;&nbsp;&nbsp;&nbsp;Followed fast and followed faster till his songs one burden bore—  
Till the dirges of his Hope that melancholy burden bore  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Of ‘Never—nevermore’."

&nbsp;&nbsp;&nbsp;&nbsp;But the Raven still beguiling all my fancy into smiling,  
Straight I wheeled a cushioned seat in front of bird, and bust and door;  
&nbsp;&nbsp;&nbsp;&nbsp;Then, upon the velvet sinking, I betook myself to linking  
&nbsp;&nbsp;&nbsp;&nbsp;Fancy unto fancy, thinking what this ominous bird of yore—  
What this grim, ungainly, ghastly, gaunt, and ominous bird of yore  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Meant in croaking "Nevermore."

&nbsp;&nbsp;&nbsp;&nbsp;This I sat engaged in guessing, but no syllable expressing  
To the fowl whose fiery eyes now burned into my bosom’s core;  
&nbsp;&nbsp;&nbsp;&nbsp;This and more I sat divining, with my head at ease reclining  
&nbsp;&nbsp;&nbsp;&nbsp;On the cushion’s velvet lining that the lamp-light gloated o’er,  
But whose velvet-violet lining with the lamp-light gloating o’er,  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;She shall press, ah, nevermore!

&nbsp;&nbsp;&nbsp;&nbsp;Then, methought, the air grew denser, perfumed from an unseen censer  
Swung by Seraphim whose foot-falls tinkled on the tufted floor.  
&nbsp;&nbsp;&nbsp;&nbsp;"Wretch," I cried, "thy God hath lent thee—by these angels he hath sent thee  
&nbsp;&nbsp;&nbsp;&nbsp;Respite—respite and nepenthe from thy memories of Lenore;  
Quaff, oh quaff this kind nepenthe and forget this lost Lenore!"  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Quoth the Raven "Nevermore."

&nbsp;&nbsp;&nbsp;&nbsp;"Prophet!" said I, "thing of evil!—prophet still, if bird or devil!—  
Whether Tempter sent, or whether tempest tossed thee here ashore,  
&nbsp;&nbsp;&nbsp;&nbsp;Desolate yet all undaunted, on this desert land enchanted—  
&nbsp;&nbsp;&nbsp;&nbsp;On this home by Horror haunted—tell me truly, I implore—  
Is there—is there balm in Gilead?—tell me—tell me, I implore!"  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Quoth the Raven "Nevermore."

&nbsp;&nbsp;&nbsp;&nbsp;"Prophet!" said I, "thing of evil!—prophet still, if bird or devil!  
By that Heaven that bends above us—by that God we both adore—  
&nbsp;&nbsp;&nbsp;&nbsp;Tell this soul with sorrow laden if, within the distant Aidenn,  
&nbsp;&nbsp;&nbsp;&nbsp;It shall clasp a sainted maiden whom the angels name Lenore—  
Clasp a rare and radiant maiden whom the angels name Lenore."  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Quoth the Raven "Nevermore."

&nbsp;&nbsp;&nbsp;&nbsp;"Be that word our sign of parting, bird or fiend!" I shrieked, upstarting—  
"Get thee back into the tempest and the Night’s Plutonian shore!  
&nbsp;&nbsp;&nbsp;&nbsp;Leave no black plume as a token of that lie thy soul hath spoken!  
&nbsp;&nbsp;&nbsp;&nbsp;Leave my loneliness unbroken!—quit the bust above my door!  
Take thy beak from out my heart, and take thy form from off my door!"  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Quoth the Raven "Nevermore."

&nbsp;&nbsp;&nbsp;&nbsp;And the Raven, never flitting, still is sitting, still is sitting  
On the pallid bust of Pallas just above my chamber door;  
&nbsp;&nbsp;&nbsp;&nbsp;And his eyes have all the seeming of a demon’s that is dreaming,  
&nbsp;&nbsp;&nbsp;&nbsp;And the lamp-light o’er him streaming throws his shadow on the floor;  
And my soul from out that shadow that lies floating on the floor  
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Shall be lifted—nevermore!`,
    title: '🐦‍⬛ "The Raven" by Edgar Allan Poe',
  },
  {
    artStyle: "Abstract Art",
    detail: 3,
    image: "/assets/images/examples/fox.avif",
    text: "The quick brown fox jumps over the lazy dog.",
    title: "🦊 The quick brown fox",
  },
  {
    artStyle: "Cyberpunk",
    detail: 75,
    image: "/assets/images/examples/cities.avif",
    text: "It was the best of times, it was the worst of times, it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair, we had everything before us, we had nothing before us, we were all going direct to Heaven, we were all going direct the other way—in short, the period was so far like the present period, that some of its noisiest authorities insisted on its being received, for good or for evil, in the superlative degree of comparison only.",
    title: '🏙️ "A Tale of Two Cities" by Charles Dickens',
  },
  {
    artStyle: "Flat design",
    detail: 80,
    image: "/assets/images/examples/docker.avif",
    text: `# Harnessing Efficiency and Scalability: The Advantages of Docker and Kubernetes
In the rapidly evolving landscape of software development, the quest for efficiency, reliability, and scalability in application deployment and management has led to the rise of containerization and orchestration technologies. Among the leading solutions in this domain are Docker and Kubernetes. Together, they form a powerful combination that revolutionizes the way developers build, deploy, and manage applications. This post explores the distinct advantages of Docker and Kubernetes, shedding light on why they have become indispensable tools in modern software development.

## The Docker Advantage

**Simplified Configuration**: Docker containers encapsulate everything an application needs to run, including its libraries, dependencies, and environment variables. This simplifies the configuration process, ensuring that an application runs the same way, irrespective of where it is deployed.

**Rapid Application Deployment**: Docker's containerization approach significantly reduces deployment times. Since containers share the host system's kernel (but can be isolated from other containers), they are lightweight and start much faster than traditional virtual machines.

**Enhanced Developer Productivity and Efficiency**: Docker streamlines the development lifecycle by facilitating continuous integration and continuous deployment (CI/CD) workflows. Developers can focus on building software without worrying about the underlying infrastructure.

**Portability Across Different Environments**: One of Docker's most significant advantages is its ability to run on any system that supports Docker—a developer's laptop, physical or virtual machines in a data center, cloud providers, etc.—thus eliminating the "it works on my machine" problem.

**Isolation**: Docker ensures that applications are isolated not only from each other but also from the host system, enhancing security. Each container has its own filesystem, ensuring that processes cannot interfere with each other or the host system.

## The Kubernetes Edge

**Automated Rollouts and Rollbacks**: Kubernetes allows you to describe the desired state for your deployed containers using declarative configuration. It automatically manages the application deployment process, scaling up or down, and rolling out updates or rollbacks, ensuring high availability.

**Load Balancing and Service Discovery**: Kubernetes can expose a container using the DNS name or using their IP address. If traffic to a container is high, Kubernetes is able to load balance and distribute the network traffic so that the deployment is stable.

**Orchestration of Containers at Scale**: Kubernetes excels in managing not just a handful but thousands of containers simultaneously. It automates the distribution of containers across a cluster, manages their lifecycle, and scales them according to demand.

**Self-healing**: Kubernetes monitors the health of nodes and containers and replaces the ones that fail, ensuring that the application is always running as intended. It restarts containers that fail, replaces containers, kills containers that don't respond to user-defined health checks, and doesn't advertise them to clients until they are ready to serve.

**Secret and Configuration Management**: Kubernetes allows you to store and manage sensitive information such as passwords, OAuth tokens, and SSH keys. You can deploy and update secrets and application configuration without rebuilding your container images and without exposing secrets in your stack configuration.

## Conclusion

The synergy of Docker and Kubernetes introduces a paradigm shift in how applications are deployed and managed, making software development more efficient, scalable, and reliable. Docker simplifies the creation and management of containerized applications, while Kubernetes provides a robust framework for automating deployment, scaling, and operations of application containers across clusters of hosts. Together, they offer a comprehensive ecosystem for building, delivering, and scaling applications with ease and precision.

Embracing Docker and Kubernetes can significantly reduce the complexity of managing application lifecycles, enabling businesses to focus more on development and less on the intricacies of deployment and scaling. As the digital landscape continues to evolve, Docker and Kubernetes will undoubtedly play a pivotal role in shaping the future of software development and deployment.
   `,
    title: "🐳🚢 A technical blog post talking about Docker and Kubernetes",
  },
  {
    artStyle: "Pop Art",
    detail: 65,
    image: "/assets/images/examples/leadership.avif",
    text: `### Transforming Challenges into Opportunities: The Essence of Leadership

In today's rapidly evolving business landscape, the true test of leadership doesn't just lie in steering teams towards set goals but in transforming unforeseen challenges into springboards for growth and innovation. As HR professionals, we've seen firsthand how the dynamics of leadership are shifting, moving away from traditional command-and-control models to embrace a more inclusive, adaptive approach.

Leadership today is synonymous with resilience and adaptability. It's about understanding that each challenge, no matter how daunting, holds within it the seed of opportunity. Whether it's navigating through the complexities of remote work, fostering a culture of continuous learning, or ensuring our teams remain engaged and motivated in the face of uncertainty, the essence of leadership lies in our response to these challenges.

One of the most inspiring aspects of modern leadership is the emphasis on empathy and emotional intelligence. Leaders who prioritize understanding the unique needs and perspectives of their team members are able to create an environment where everyone feels valued and empowered. This approach not only enhances team cohesion but also drives innovation, as diverse ideas and viewpoints are freely shared and explored.

Moreover, the role of a leader today extends beyond mere management. It's about being a mentor and a coach, providing guidance and support while also encouraging autonomy and self-growth. It's about setting a vision that resonates with the entire team, inspiring them to exceed their own expectations in pursuit of shared goals.

In embracing these principles, we foster a culture of leadership that permeates every level of our organization. It's a culture where challenges are met with creativity and courage, where each team member feels empowered to take initiative, and where leadership is not just a position but a shared responsibility.

As we continue to navigate the complexities of the modern workplace, let us remember that the heart of leadership is not in the titles we hold but in the impact we make. It's in the moments we inspire, the challenges we overcome together, and the opportunities we create for our teams to thrive.

To all the leaders out there, remember: your ability to adapt, empathize, and inspire is what truly makes a difference. Let's continue to lead with courage, compassion, and an unwavering commitment to excellence.
`,
    title: "🗄️ A LinkedIn post by an HR manager",
  },
  {
    artStyle: "Dreamy Fantasy",
    detail: 40,
    image: "/assets/images/examples/frame.avif",
    text: `Dive into the enchanting world of fantasy movies with "**Fantastical Frames: Journey Through Cinema Magic**," a podcast that explores the spellbinding stories, iconic characters, and the visionary filmmakers behind some of the most beloved fantasy films of all time. From the sweeping epics of Middle-earth to the whimsical escapades in magical kingdoms, each episode invites listeners to embark on a journey through the making of these cinematic treasures.

Hosted by **Alex and Jordan**, two avid fantasy film enthusiasts with a penchant for storytelling, "**Fantastical Frames**" offers listeners an insider's look into the creative process behind the movies that transport us to worlds beyond our imagination. With a mix of expert analysis, behind-the-scenes tales, and engaging interviews with filmmakers, actors, and industry insiders, this podcast peels back the curtain on the magic of movie-making.
    
Whether you're a die-hard fan of classic fantasy adventures or curious about the latest releases in the genre, "**Fantastical Frames**" promises to be your guide through the realms of cinema magic. Join us as we discuss the themes, special effects, and the cultural impact of fantasy movies, celebrating the artistry that makes these films a cornerstone of cinematic history.
    `,
    title: "🎙️ A fantasy film podcast description",
  },
  {
    artStyle: "Anime",
    detail: 75,
    image: "/assets/images/examples/campaign.avif",
    text: `Embark on an epic journey through the mystical lands of Eldoria in "Chronicles of the Forgotten Realms," a Dungeons & Dragons campaign that promises adventure, intrigue, and endless possibilities. Assemble your party of brave adventurers, each with their own unique backstory and quest for glory, as you navigate treacherous landscapes, ancient ruins, and dark dungeons in search of legendary treasures and truths long buried.
    
**Campaign Overview:**
"Chronicles of the Forgotten Realms" invites players to explore the rich tapestry of Eldoria, a world where magic intertwines with the fates of nations and the echoes of ancient battles shape the present. From the soaring peaks of the Dragonspine Mountains to the shadowy depths of the Underrealm, adventurers will forge alliances, battle fearsome creatures, and uncover secrets that could alter the course of history.

**Your Quest:**
The land is on the brink of turmoil, as dark forces conspire to unleash an ancient evil that has been dormant for centuries. Your party must embark on a perilous quest to gather the three lost artifacts of power, the only means to seal away the darkness threatening to engulf the world. Along the way, you will face moral dilemmas, complex characters with hidden agendas, and the very real possibility that the greatest threat might come from within.

**Features:**
- **Dynamic World**: Eldoria is a living, breathing world where your actions have consequences. The choices you make will influence the fate of kingdoms, the allegiance of allies, and the outcome of the campaign.
- **Rich Lore & History**: Delve into a deeply crafted lore, uncovering the mysteries of ancient civilizations, forgotten magic, and artifacts of immense power.
- **Challenging Encounters**: Engage in strategic combat against a variety of enemies, each requiring a unique approach. Test your wits and skills against creatures of legend.
- **Character Development**: Experience character growth not just in power but in depth. Your character's backstory, choices, and interactions will shape their journey and the story at large.

**Join Us:**
Whether you're a seasoned veteran of D&D or new to the world of tabletop RPGs, "Chronicles of the Forgotten Realms" offers a welcoming and immersive experience. Gather your friends, roll the dice, and step into a world of adventure where the only limit is your imagination. The fate of Eldoria rests in your hands. Are you ready to become a legend?
`,
    title: "🐉 A Dungeons & Dragons campaign",
  },
  {
    artStyle: "Concept Art",
    detail: 45,
    image: "/assets/images/examples/podcast.avif",
    text: `Each week we choose a theme. Then anything can happen. **This American Life** is true stories that unfold like little movies for radio. Personal stories with funny moments, big feelings, and surprising plot twists. Newsy stories that try to capture what it’s like to be alive right now. It’s the most popular weekly podcast in the world, and winner of the first ever Pulitzer Prize for a radio show or podcast. Hosted by Ira Glass and produced in collaboration with WBEZ Chicago.`,
    title: `📻 "This American Life" podcast description`,
  },
] satisfies {
  text: string
  image: string
  title: string
  artStyle: string
  detail: number
}[]
