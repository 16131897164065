import { ScrollShadow, Select, SelectItem } from "@nextui-org/react"
import { useState } from "react"
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider"
import Markdown from "react-markdown"

import examples from "./example-list"

export default function Examples() {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const example = examples[selectedIndex]

  return (
    <section className="py-24 sm:py-32" id="examples">
      <div className="mx-auto max-w-7xl px-6 md:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-black leading-7 text-[#fe9125]">
              Examples
            </h2>
            <p className="font-headline text-3xl tracking-wide text-white sm:text-5xl sm:leading-tight">
              See it for yourself
            </p>
            <p className="mt-6 text-lg font-light leading-8 text-gray-200">
              Here are some examples of what Wordbrush can do. Blogs, books or
              poems; if you can write it, you can bring it to life.
            </p>
          </div>
        </div>
      </div>
      <div className="mx-auto mt-10 flex max-w-3xl flex-col items-center gap-y-10 px-6 md:max-w-5xl md:px-8">
        <Select
          aria-label="Example"
          className="max-w-md"
          selectedKeys={[example?.title ?? ""]}
          selectionMode="single"
          size="sm"
          variant="flat"
        >
          {examples.map((example, index) => (
            <SelectItem
              key={example.title}
              onClick={() => {
                setSelectedIndex(index)
              }}
              value={index}
            >
              {example.title}
            </SelectItem>
          ))}
        </Select>
        <ReactCompareSlider
          className="h-[500px] w-full"
          itemOne={
            <ScrollShadow className="mx-auto h-full max-w-xl" hideScrollBar>
              <div className="prose prose-sm prose-headings:text-white prose-strong:text-white md:prose-lg flex flex-col text-gray-200">
                <Markdown>{example?.text}</Markdown>
              </div>
            </ScrollShadow>
          }
          itemTwo={
            <div className="relative flex h-full w-full items-center justify-center">
              <ReactCompareSliderImage
                alt="Generated by Wordbrush"
                src={example?.image}
              />
              <div className="absolute bottom-0 right-0 px-6 py-3">
                <p className="text-sm text-white/50">
                  <span className="font-semibold">Art Style: </span>
                  {example?.artStyle} •{" "}
                  <span className="font-semibold">Detail Level:</span>{" "}
                  {example?.detail}
                </p>
              </div>
            </div>
          }
          key={example?.image}
          position={100}
        />
      </div>
    </section>
  )
}
