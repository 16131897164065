import { Disclosure, Transition } from "@headlessui/react"
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"

const faqs: { answer: string; question: string }[] = [
  {
    answer: `You can use Wordbrush to generate images for all types of written work: 
    personal blog articles, your company's blog, your newsletter, or your book (covers and illustrations).
    You can also generate images to use for your podcast, such as the cover or specific episode covers.`,
    question: "What can I use Wordbrush for?",
  },
  {
    answer: `We utilize AI technology to go over your text and ideate visually appealing ideas from your content,
    based on the key ideas of the text and the intended use you selected.`,
    question: "How do you generate images from text?",
  },
  {
    answer: `The AI is instructed to closely follow the message of your text, which varies depending on your intended use;
    this makes it so the generated image is relevant for your objective. If you have ideas in mind, you can specify certain
    elements to appear (or to be excluded) from the result.`,
    question: "How do you ensure the relevance of generated images to my text?",
  },

  {
    answer: `You can customize the following: art style (from over 50+), aspect ratio (square, landscape, portrait), 
      the level of detail (minimalist and abstract to highly-detailed and realistic), 
      the mood, key elements to include and elements to exclude`,
    question: "What kind of customization options are available?",
  },
  {
    answer:
      "Square: 1024×1024. Landscape: 1792×1024. Portrait: 1024×1792. All images are in PNG format.",
    question: "What formats and sizes are available for the generated images?",
  },
  {
    answer: "Currently images can't be edited.",
    question: "Can I edit the images generated by Wordbrush?",
  },
  {
    answer:
      "Of course! We grant you full commercial license and ownership over the images you create.",
    question: "Can I use Wordbrush images for commercial purposes?",
  },
  {
    answer:
      "There is no limit on how many images you can generate, as long as you have sufficient credits.",
    question: "Is there a limit to how many images I can generate?",
  },
  {
    answer:
      "We do not store your writing on our servers, everything is saved locally on your machine.",
    question: "What privacy measures do you take with my writing?",
  },
  {
    answer: "Send us an email at contact@wordbrush.art.",
    question: "How can I report a problem or get support for issues?",
  },
]

export default function FAQ() {
  return (
    <section
      className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40"
      id="faq"
    >
      <div className="mx-auto grid grid-cols-5 gap-y-10 divide-y divide-white/10 md:flex-row md:gap-x-12 md:divide-y-0">
        <div className="col-span-5 md:col-span-2">
          <h2 className="font-headline text-4xl leading-tight tracking-wide text-white">
            Frequently asked questions
          </h2>
          <p className="mt-5 text-sm font-semibold text-gray-200">
            Have any more questions?{" "}
            <a className="text-[#fe9125]" href="mailto:contact@wordbrush.art">
              Contact us
            </a>
          </p>
        </div>

        <dl className="col-span-5 max-w-4xl space-y-6 divide-y divide-white/10 md:col-span-3">
          {faqs.map((faq, index) => (
            <Disclosure
              as="div"
              className={clsx("pt-6", { "pt-6 md:pt-0": index === 0 })}
              key={faq.question}
            >
              {({ open }) => (
                <>
                  <dt>
                    <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                      <span className="text-base font-semibold leading-7">
                        {faq.question}
                      </span>
                      <span className="ml-6 flex h-7 items-center">
                        {open ? (
                          <MinusIcon aria-hidden="true" className="h-6 w-6" />
                        ) : (
                          <PlusIcon aria-hidden="true" className="h-6 w-6" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Transition
                    enter="transition duration-1000 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-100 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                    show={open}
                  >
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-200">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </Transition>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </section>
  )
}
